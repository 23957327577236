/** ========================================================================= *\
 * Puchong Horizon
 * @author Chris Tang
 * @organization Forefront International
 * @date 01/2018
 * ========================================================================== */


/* Globals Variables
 * ========================================================================== */
var isMobile = false;
var navOpen = false;
var mobileNav = false;
var mainMenu = $(".mainMenu");


/* Entry Function
 * ========================================================================== */
$(document).ready(main);
function main() {
	$(".animsition-overlay").animsition({
		inClass: 'overlay-slide-in-top',
		outClass: 'overlay-slide-out-top',
		inDuration: 1500,
		outDuration: 800,
		linkElement: '.animsition-link',
		// e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
		loading: true,
		loadingParentElement: 'body', //animsition wrapper element
		loadingClass: 'animsition-loading',
		loadingInner: '', // e.g '<img src="loading.svg" />'
		timeout: false,
		timeoutCountdown: 5000,
		onLoadEvent: true,
		browser: [ 'animation-duration', '-webkit-animation-duration'],
		// "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
		// The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
		overlay : false,
		overlayClass : 'animsition-overlay-slide',
		overlayParentElement : 'body',
		transition: function(url){ window.location.href = url; }
	});
	isMobile = initIsMobile();
	$(".focuspoint").focusPoint();
	
	//Enable/Disable Scrollify
	if ( isMobile.any() ) {
		setMobileNavHeight();
		fixNavBar();
	}else if ( window.innerWidth < 768 ) {
		setMobileNavHeight();
		fixNavBar();
	}else{
		initScrollify();
		fixThankyou();
		fixModal();
	}
	
	// Enable Animations
	animateHome();
	animateScrollBtn();
	enableMobileNav();
	masterplanBtn();
	
	// Enable functionality
	enableMainNav();
	resizeMenu();
	scrollPDPA();

	//start drift.js
	driftCheck(isMobile.any());
};



/* IsMobile Function
 * ========================================================================== */
function initIsMobile() {
  return {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPod/i);
    },
    iPad: function() {
      return navigator.userAgent.match(/iPad/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    }
  };
}


/* Scrollify
* ========================================================================== */
function initScrollify(){
	$.scrollify({
		section : ".section",
		sectionName : "section-name",
		interstitialSection : ".footer",
		easing: "easeOutExpo",
		scrollSpeed: 1100,
		offset : 0,
		scrollbars: true,
		standardScrollElements: "",
		setHeights: true,
		overflowScroll: true,
		updateHash: true,
		touchScroll:true,
		before:function() {
			//Fixed nav position
			if ( $.scrollify.currentIndex() < 1 ) {
				$("nav").removeClass("fixed");
			}

			animateContentOut();
		},
		after:function() {
			//Fixed nav position
			if ( $.scrollify.currentIndex() > 0 ) {
				$("nav").addClass("fixed");
			}else{
				$("nav").removeClass("fixed");
			}

			animateContentIn();
		},
		afterResize:function() {
			$.scrollify.update();
			if ( window.innerWidth < 768 ) {
				var animEl = $("[data-anim]"),
					animVal = animEl.data("anim");
					TweenMax.staggerTo(animEl, 0.5, { y: 0, autoAlpha: 1 }, 0.1);
					animEl.attr("data-anim", "in");
					animEl.data("anim", "in");
				
			};
		},
		afterRender:function() {}
	});
};

//Disable Scrollify
function disableScrollify(){
	$.scrollify.disable();
}

//Enable Scrollify
function enableScrollify(){
	$.scrollify.enable();
}



/* Main Navigation
 * ========================================================================== */
function enableMainNav(){
	$("nav a").click(function(){
		var targetLink = $(this).attr("href"),
				topY,
				menuBtn =$('.menuBtn'),
				a = Expo.easeOut,
				nav = $("nav").height();
				
		(targetLink =="#intro")?topY = $(targetLink).offset().top:topY = $(targetLink).offset().top-nav;

		if ( $.scrollify.isDisabled() == false ){
			$.scrollify.move(targetLink);
		}else{
			TweenMax.to($(window), 0.5, { scrollTo: {y: topY, autoKill: true} });
		}
		if ( navOpen == false ){
			TweenMax.to(mainMenu, 0.8, {height: mainMenu.attr('initH'), autoAlpha: 1, ease: a});
			menuBtn.addClass("active");
			navOpen = true;
		}else {
			TweenMax.to(mainMenu, 0.8, {height: "0%", autoAlpha: 0, ease: a});
			menuBtn.removeClass("active");
			navOpen = false;
		};
		// return false;
	});
};

//Mobile Navigation
function enableMobileNav(){
	//Menu toggle
	$('.menuBtn').click(function() {
	   
		var menuBtn = $(this),
			a = Expo.easeOut;
	   
	   if ( navOpen == false ){
		   TweenMax.to(mainMenu, 0.8, {height: mainMenu.attr('initH'), autoAlpha: 1, ease: a});
		   menuBtn.addClass("active");
		   navOpen = true;
	   }else {
		   TweenMax.to(mainMenu, 0.8, {height: "0%", autoAlpha: 0, ease: a});
		   menuBtn.removeClass("active");
		   navOpen = false;
	   };	   
	   return false;
	});
}

//Mobile Navigation Height
function setMobileNavHeight(){
	mainMenu.attr('initH', mainMenu.height());
	TweenMax.set(mainMenu, {height: 0});
	if ( window.innerWidth > 768 ){
		TweenMax.to(mainMenu, 0, {clearProps: 'all'});
	}
}

//Reset Mobile Navigation
function resetMobileNav(){
	TweenMax.to(mainMenu, 0, {clearProps: 'all'});
	mainMenu.attr('initH', mainMenu.height());
	if ( navOpen == true ){
		TweenMax.to(mainMenu, 0.8, {height: "0%", autoAlpha: 0, ease: Expo.easeOut});
		$('.menuBtn').removeClass("active");
		navOpen = false;
	};	   
}

//Fixed Navigation Bar
function fixNavBar(){
	var $intro = $("#intro"),
			introOffset = $intro.offset().top;
			
	$(window).on('scroll', function(){
		if ( $(window).scrollTop() > introOffset ) {
			$("nav").addClass("fixed");
		}else{
			$("nav").removeClass("fixed");
		}
	})
}

//Thankyou Page
function fixThankyou(){
	if ( $(".wrapper").find(".thank-you").length > 0 ) {
		disableScrollify();
	}
}


/* Resize animation
 * ========================================================================== */
function resizeMenu(){
	$(window).on("resize", function(){
		if ( window.innerWidth < 768 ){
			disableScrollify();
			fixNavBar();
		}else{
			if ( !isMobile.any() || !isMobile.iPad() ) {
				enableScrollify();
				fixThankyou();
			}
		}
		resetMobileNav();
	})
}


/* Entry Animation
 * ========================================================================== */
//Scroll btn animation
function animateScrollBtn(){
	var tmax_opts = { repeat: -1, yoyo: true };
  	var tmax_scroll = new TimelineMax(tmax_opts);

	tmax_scroll.to($(".scroll-btn"), 0.5, { top: 14 });
}

//Slide btn animation
function animateSlideBtn(){
	var tmax_opts = { repeat: -1 };
  	var tmax_slide = new TimelineMax(tmax_opts);

	tmax_slide.to($(".slide-btn"), 2, { rotation: 45, transformOrigin:"right 50%" });
}

//Animate Home
function animateHome(){
	TweenMax.staggerFrom([$(".home-logo-svg"), $(".scroll-icon"), $(".slide-icon")], 0.8, { delay: 1, y: -50, autoAlpha: 0, onComplete: animateSlideBtn }, 0.1);
}

//Animate Content Out
function animateContentOut(){
	var animEl = $("[data-anim]"),
			animVal = animEl.data("anim");
	TweenMax.staggerTo(animEl, 0.5, { y: -30, autoAlpha: 0 }, 0.1);	
	animEl.attr("data-anim", "out");
	animEl.data("anim", "out");
}

//Animate Content In
function animateContentIn(){
	var currentParent = $.scrollify.current(),
			animEl = currentParent.find("[data-anim]"),
			animVal = animEl.data("anim");
	if ( animVal == "out" ){
		TweenMax.staggerTo(animEl, 0.5, { y: 0, autoAlpha: 1 }, 0.1);
		animEl.attr("data-anim", "in");
		animEl.data("anim", "in");
	}
}


/* Modal Fixed on desktop
 * ========================================================================== */
function fixModal(){
	var modalEl = $("[data-toggle]");
	modalEl.click(function(){
		disableScrollify();
	})

	$('.modal').on('hide.bs.modal', function (e) {
		enableScrollify();
		fixThankyou();
	})
}

function scrollPDPA(){
	$('.modal').on('shown.bs.modal', function() {
		var $pdpaBtn = $("[data-pdpa]");
		var $thisModal = $(this);
		$pdpaBtn.click(function(){
			var $scrollData = $(this).data("pdpa");
			var $scrollTarget = $("#" + $scrollData);
			var pdpaOffset = $scrollTarget.offset();
			var pdpaParentOffset = $scrollTarget.parents(".modal").offset();
			var pdpaTopY = pdpaOffset.top - pdpaParentOffset.top;
			TweenMax.to($thisModal, 0.5, { scrollTo: {y: pdpaTopY, autoKill: true} });
		})
	})
}


/* Google Event Tracking
 * ========================================================================== */
function enableEventTracking() {
	var clickTrackers = document.querySelectorAll("[gtag-click]");
	clickTrackers.forEach(function(element) {
	  element.addEventListener('click', function() {
		var eventString = element.getAttribute('gtag-click');
		var split = eventString.split(';');
		var eventName = split[0];
		var eventLabel = split[1];
		gtag('event', eventName, {
		  'event_label': eventLabel
		});
	  })
	})
  }

/* Zoom in Map (Drift.js)
 * ========================================================================== */
function driftCheck(mobile){
	if(mobile){
		var localeMap = document.querySelector('.location-map');

		new Drift( localeMap, {
			paneContainer: localeMap,
			inlinePane: false,
			zoomFactor:2.2,

			onShow:function (){
				localeMap.classList.add('drift-container-in');
				localeMap.classList.remove('drift-container-out');
			},
			onHide:function (){
				localeMap.classList.remove('drift-container-in');
				localeMap.classList.add('drift-container-out');
			}
		});
	}
}

/*  Master plan button Animation
 * ========================================================================== */
function masterplanBtn(){
	TweenMax.set($('#AviarySvg'),{transformOrigin: "50% 50% 0", scale:1});
	TweenMax.fromTo( $('#AviarySvg'), 0.7 ,{opacity:0.8},{opacity:1,yoyo:true,repeat:-1, ease:Quad.easeInOut});
}
