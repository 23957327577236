var canvas = document.getElementById('canvas');
var ctx = canvas.getContext('2d');
  
canvas.height = window.innerHeight;
canvas.width = window.innerWidth;

var waves = [["rgba(255, 232, 179, 0)","rgba(255, 232, 179, 0.4)","rgba(252, 191, 14, 0)"],
["rgba(241,136,26,0)","rgba(241,136,26,0.4)","rgba(241,136,26,0)"],
["rgba(255,255,255,0)","rgba(255,255,255,0.4)","rgba(255,255,255,0)"],
["rgba(255,230,172,0)","rgba(255,230,172,0.4)","rgba(255,230,172,0)"],
["rgba(241,136,26,0)","rgba(241,136,26,0.4)","rgba(241,136,26,0)"],
["rgba(255, 232, 179, 0)","rgba(255, 232, 179, 0.4)","rgba(252, 191, 14, 0)"]]

var i = 0;

function draw() {
  canvas.width = canvas.width;

  for(var j = waves.length - 1; j >= 0; j--) {
    var offset = i + j * Math.PI * 12;
    var my_gradient=ctx.createLinearGradient(0,0,0,window.innerHeight);
    my_gradient.addColorStop(0,waves[j][0]);
    my_gradient.addColorStop(0.5,waves[j][1]);
    my_gradient.addColorStop(1,waves[j][2]);
    ctx.fillStyle = my_gradient;

    var randomLeft            = (Math.sin(offset/500)  + 1)       / 2 * 100;
    var randomRight           = (Math.sin((offset/500) + 5) + 1) / 2 * 500;
    var randomLeftConstraint  = (Math.sin((offset/200)  + 2)  + 3) * 300;
    var randomRightConstraint = (Math.sin((offset/200)  + 1)  + 3) * 200;

    ctx.beginPath();
    ctx.moveTo(0, randomLeft + 600);

    ctx.bezierCurveTo(canvas.width / 3, randomLeftConstraint, canvas.width / 3 * 2, randomRightConstraint, canvas.width, randomRight + 300);
    ctx.lineTo(canvas.width , canvas.height);
    ctx.lineTo(0, canvas.height);
    ctx.lineTo(0, randomLeft + 100);

    ctx.closePath();
    ctx.fill();
  }

  i = i + 3; 
}

function animate(){
	animID = window.webkitRequestAnimationFrame(animate);
  draw();
}

animate();